import { getPublicCompressed } from '@toruslabs/eccrypto';
import { CHAIN_NAMESPACES, WALLET_ADAPTERS } from '@web3auth/base';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter, UX_MODE } from '@web3auth/openlogin-adapter';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import RPC from './evm';

import { t } from 'i18next';
import Confirmable from '../../components/confirmable';
import Toast from '../../components/toast';
// import { CHAIN_LIST } from '../../constants/chains';
import Misc from '../../utils/misc';
import { STORAGE, getLocalStorage, setLocalStorage, removeLocalStorage } from '../../utils/storage';
import { MobXProviderContext } from 'mobx-react';
import { useAuth } from '../../hooks/auth';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { LOGIN_TYPE } from '../../constants/common';
import { encryptData } from '../../utils/encrypt-key';

export const Web3AuthContext = createContext({
  web3Auth: null,
  provider: null,
  isLoading: false,
  setIsLoading: async () => {},
  user: null,
  expiredToken: null,
  refreshToken: null,
  login: async (adapter, provider, login_hint) => {},
  logout: async () => {},
  getUserInfo: async () => {},
  getAccounts: async () => {},
  getBalance: async () => {},
  getAppPubKey: async () => {},
  getPrivateKey: async () => {},
  signMessage: async () => {},
  ethereum: null,
  setExpiredToken: () => {},
  setRefreshToken: () => {},
});

export function useWeb3Auth() {
  return useContext(Web3AuthContext);
}

// const env = process.env.REACT_APP_ENV;
// const networkWeb3auth = process.env.REACT_APP_WEB3_AUTH_NETWORKS;
// const network = getNetworkSelected(env, getLocalStorage('chain'));
const currentChainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: '0x1', // Please use 0x1 for Mainnet
  rpcTarget: 'https://rpc.ankr.com/eth',
  displayName: 'Ethereum Mainnet',
  blockExplorerUrl: 'https://etherscan.io/',
  ticker: 'ETH',
  tickerName: 'Ethereum',
};

const clientId = process.env.REACT_APP_WEB3_AUTH_CLIENT_ID;
const oAuthClientId = process.env.REACT_APP_OAUTH_CLIENT_ID;
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const verifier = process.env.REACT_APP_VERIFIER;
const emailVerifier = process.env.REACT_APP_EMAIL_VERIFIER;
const googleVerifier = process.env.REACT_APP_GOOGLE_VERIFIER;
const domainOAuth = process.env.REACT_APP_DOMAIN_OAUTH;
const secretKey = process.env.REACT_APP_SECRET_KEY;
const secretIv = process.env.REACT_APP_SECRET_IV;

export const Web3AuthProvider = ({ children }) => {
  const { auth } = useContext(MobXProviderContext);
  const { onLoginAction } = useAuth();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const game_url = searchParams.get('game_url');
  const [web3Auth, setWeb3Auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expiredToken, setExpiredToken] = useState(getLocalStorage('EXPIRED_TOKEN') || null);
  const [refreshToken, setRefreshToken] = useState(getLocalStorage('REFRESH_TOKEN') || null);

  // Web3Auth configuration
  const web3AuthConfig = {
    chainConfig: currentChainConfig,
    enableLogging: true,
    clientId: clientId,
    uiConfig: {
      defaultLanguage: getLocalStorage(STORAGE.CURRENT_LANG) || 'en',
    },
  };

  // OpenloginAdapter configuration
  const openloginConfig = {
    privateKeyProvider: new EthereumPrivateKeyProvider({
      config: {
        chainConfig: currentChainConfig,
      },
    }),
    loginSettings: {
      mfaLevel: 'none',
    },
    adapterSettings: {
      clientId,
      uxMode: UX_MODE.REDIRECT,
      loginConfig: {
        google: {
          verifier,
          verifierSubIdentifier: googleVerifier,
          typeOfLogin: 'google',
          clientId: googleClientId,
        },
        auth0emailpasswordless: {
          verifier,
          verifierSubIdentifier: emailVerifier,
          typeOfLogin: 'jwt',
          clientId: oAuthClientId,
        },
      },
    },
  };

  // Function to initialize Web3Auth
  const initializeWeb3Auth = async () => {
    try {
      // Create instance of Web3Auth and OpenloginAdapter
      const web3AuthInstance = new Web3AuthNoModal(web3AuthConfig);
      const openloginAdapter = new OpenloginAdapter(openloginConfig);

      // Configure the adapter for Web3Auth
      web3AuthInstance.configureAdapter(openloginAdapter);

      // Initialize Web3Auth
      await web3AuthInstance.init();
      return web3AuthInstance;
    } catch (error) {
      throw new Error(error?.message || 'Initial web3 auth fail.');
    }
  };

  // Function to handle login logic
  const handleLogin = async web3AuthInstance => {
    // Get user information from Web3Auth
    const userInfo = await web3AuthInstance.getUserInfo();
    const rpc = new RPC(web3AuthInstance.provider);
    const address = await rpc.getAccounts();
    const gameUrl = getLocalStorage(STORAGE.GAME_URL) || game_url;
    const token = getLocalStorage(STORAGE.ACCESS_TOKEN);
    const loginType = getLocalStorage(STORAGE.LOGIN_TYPE);
    const bounty = getLocalStorage(STORAGE.TYPE_BOUNTY);
    // const typeConnect = getLocalStorage(STORAGE.TYPE_CREATE_WALLET);
    if (bounty) {
      setLocalStorage(STORAGE.EMAIL, userInfo?.email);
    }
    const privateKey = await web3AuthInstance.provider?.request({ method: 'eth_private_key' });
    const encryptKey = encryptData(privateKey, secretKey, secretIv)

    if (!token) {
      // If game URL or token is not found, generate new authentication payload
      const appPubKey = await getAppPubKey(web3AuthInstance.provider);
      const payload = {
        login_type: loginType || 'WEB3_AUTH',
        data: {
          access_token: userInfo?.idToken,
          public_address: address[0],
          app_pub_key: appPubKey,
          ip_address: '',
          language_code: getLocalStorage(STORAGE.CURRENT_LANG) || 'en',
          private_key: encryptKey,
        },
      };

      const res = await auth.getAuthCode(payload);
      await onLoginAction(res, address[0], userInfo, gameUrl);
    } else {
      // Set initial user data if token is found
      const { email, name } = userInfo;
      auth.setInitialData({
        email,
        userName: name,
        publicAddress: address[0],
      });
    }
    // if (typeConnect) {
    //   console.log(1111111111);
    // }
  };

  const handleInit = async () => {
    // setIsLoading(true);
    try {
      // Initialize Web3Auth instance
      const web3AuthInstance = await initializeWeb3Auth();
      setWeb3Auth(web3AuthInstance);
      const typeLogin = getLocalStorage(STORAGE.LOGIN_TYPE);
      if (web3AuthInstance.status === 'connected' && typeLogin !== LOGIN_TYPE.IMMUTABLE) {
        // If connected, set provider and handle login
        setProvider(web3AuthInstance.provider);
        auth?.setLoggedIn(true);
        await handleLogin(web3AuthInstance);
      }
      return web3AuthInstance;
    } catch (error) {
      Toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginGoogle = async loginType => {
    const web3Login = await handleInit();
    removeLocalStorage(STORAGE.TYPE_BOUNTY);
    try {
      if (!web3Auth || !web3Login) {
        return;
      }
      setLocalStorage(STORAGE.LOGIN_TYPE, loginType);
      await web3Login.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
        loginProvider: 'google',
      });
      if (web3Login.status === 'connected') {
        setProvider(web3Login.provider);
        auth?.setLoggedIn(true);
      }
    } catch (error) {
      if (error?.code === 5111) {
        await logout();
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                {/* <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })} */}
              </>
            ),
            hideCancelButton: true,
          });
        }
      } else {
        await logout();
      }
      throw error;
    }
  };

  const loginAuth0EmailPasswordLess = async loginType => {
    const web3Login = await handleInit();
    try {
      if (!web3Login) {
        return;
      }

      setLocalStorage(STORAGE.LOGIN_TYPE, loginType);
      setLocalStorage(STORAGE.TYPE_BOUNTY, 'bounty');
      const emailLast = getLocalStorage(STORAGE.EMAIL);
      const payload = {
        loginProvider: 'auth0emailpasswordless',
        extraLoginOptions: {
          domain: domainOAuth,
          // this corresponds to the field inside jwt which must be used to uniquely
          // identify the user. This is mapped b/w google and email passwordless logins of Auth0
          verifierIdField: 'email',
          isVerifierIdCaseSensitive: false,
          request_language: getLocalStorage(STORAGE.CURRENT_LANG) || 'en',
        },
      };

      if (emailLast) {
        Object.assign(payload, {
          login_hint: emailLast,
        });
      }

      await web3Login.connectTo(WALLET_ADAPTERS.OPENLOGIN, payload);
      if (web3Login.status === 'connected') {
        setProvider(web3Login.provider);
        auth?.setLoggedIn(true);
      }
    } catch (error) {
      if (error?.code === 5111) {
        await logout();
      }

      if (error.error === undefined && error.mesage === undefined) {
        if (Misc.isMobile && !Misc.checkNotMetamaskBrowser()) {
          Confirmable.open({
            content: (
              <>
                {t('validation_messages:SOMETHING_WENT_WRONG')}
                {/* <br />
                {t('common:metamask_switch_network_to', { network: CHAIN_LIST[network?.chainId].displayName })} */}
              </>
            ),
            hideCancelButton: true,
          });
        }
      } else {
        await logout();
      }
      throw error;
    }
  };

  const logout = async () => {
    if (!web3Auth) {
      return;
    }
    try {
      if (web3Auth.connected) {
        await web3Auth.logout();
      }
    } catch (e) {}

    setProvider(null);
  };

  const getUserInfo = async () => {
    try {
      if (!web3Auth) {
        return;
      }

      const user = await web3Auth?.getUserInfo();

      return user;
    } catch (error) {
      return error?.message;
    }
  };

  const getAccounts = useCallback(async () => {
    if (!provider) {
      return null;
    }
    const rpc = new RPC(provider);
    const userAccount = await rpc.getAccounts();

    return userAccount;
  }, [provider]);

  const getChainId = async () => {
    if (!provider) {
      return null;
    }
    const chainId = await provider.getCurrentChainId();

    return chainId;
  };

  const getBalance = async () => {
    if (!provider) {
      return;
    }
    const balance = await provider.getBalance();

    return balance;
  };

  const getAppPubKey = async provider => {
    try {
      const app_scoped_privkey = await provider?.request({
        method: 'eth_private_key', // use "private_key" for other non-evm chains
      });

      const app_pub_key = getPublicCompressed(Buffer.from(app_scoped_privkey.padStart(64, '0'), 'hex')).toString('hex');

      return app_pub_key;
    } catch (error) {
      return error;
    }
  };

  const getPrivateKey = useCallback(async () => {
    if (!provider) {
      return null;
    }

    try {
      const privateKeyWeb3Auth = await provider?.request({ method: 'eth_private_key' });

      return privateKeyWeb3Auth;
    } catch (error) {
      return null;
    }
  }, [provider]);

  const signMessage = async message => {
    try {
      const web3 = new Web3(provider);

      // Get user's Ethereum public address
      // const fromAddress = (await web3.eth.getAccounts())[0];
      const fromAddress = (await provider.getAccounts())[0];

      // const sign = await provider.signMessage();

      // Sign the message
      const signedMessage = await web3.eth.personal.sign(
        message,
        fromAddress,
        '', // configure your own password here.
      );

      return signedMessage;
    } catch (error) {
      return error;
    }
  };

  const contextProvider = {
    web3Auth,
    provider,
    isLoading,
    expiredToken,
    refreshToken,
    setIsLoading,
    // login,
    logout,
    getUserInfo,
    getChainId,
    getAccounts,
    getBalance,
    getAppPubKey,
    getPrivateKey,
    signMessage,
    setExpiredToken,
    setRefreshToken,
    loginGoogle,
    loginAuth0EmailPasswordLess,
    initializeWeb3Auth,
    handleLogin,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
